import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 id="what-is-pier-">What is Pier?</h1>
    <ul>
    <li>a way to deploy a homeserver quickly without any required server administration knowledge</li>
    <li>a way to host services accessible from your local network, over a pre-configured WireGuard VPN connection, or over the web<ul>
    <li>services like a git server, password manager, email server, dropbox clone, torrent downloader</li>
    </ul>
    </li>
    <li>all services are containerized for security and portability. Integrating new services is straightforward.</li>
    <li>your data and configuration is kept separate from the services, such that backups and reinstallation are easy</li>
    </ul>
    <h1 id="the-targeted-user">The targeted user</h1>
    <ul>
    <li>Everyone with a Pi lying around, but mostly medium-skill users that dont have the needed knowledge of linux server administration to set up something like this themselves</li>
    <li>But also people like us who could easily set up a homeserver ourselves but who want to only do this work once, and be able to regenerate the set up if necessary</li>
    <li>Someone with a running server, as the services can be installed separately on an existing machine</li>
    </ul>
    <h1 id="why-would-you-want-to-work-on-this-">Why would you want to work on this?</h1>
    <ul>
    <li>If you have any service you would want to host, you can easily add it to our system and profit from the existing infrastructure</li>
    <li>If you like doing shit once and not having to do it again</li>
    </ul>
    <h1 id="how-is-it-built-">How is it built?</h1>
    <ul>
    <li>A script that sets up the base OS (Raspbian), and sets up all required programs, SSH access, VPN access, static IP, etc.</li>
    <li>The service stack based on docker-compose, with a neat interface which generates the docker-compose.yml files automatically from the user&#39;s configuration options</li>
    <li>All services are behind the reverse proxy Traefik, which provides routing, and optionally TLS certificate management and authentication</li>
    <li>Maybe in the future our own services on DockerHub</li>
    </ul>
    <h1 id="our-current-stage-">Our current stage:</h1>
    <ul>
    <li>A working system, which is going towards public version 1.0 within the next few months</li>
    </ul>
    <h1 id="future-work-">Future work:</h1>
    <ul>
    <li>Snoop on the traffic and determine which services (and servers) should be running.</li>
    </ul>

    <Link to="/getting-started/">How can I get started?</Link>
    <Link to="/dev/">How can I contribute?</Link>
  </Layout>
)

export default IndexPage
